import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesorderService {

  constructor(private http: HttpClient) { }
 
  saveSalesOrderMaster(salesordePayLoad):Observable<any> {

    return this.http.post<any>(environment.receivablemanagementapi + 'pointsaleorderdata', salesordePayLoad);
  }

  getSalesOrderJobByOrderNo(salesordePayLoad):Observable<any> {

    return this.http.post<any>(environment.receivablemanagementapi + 'pointSaleOrderJob/search', salesordePayLoad);
  }
  getManufacturerStatus(orderNo: any, jobNo: any): Observable<string> {
    return this.http.get(
      `${environment.receivablemanagementapi}salesorder/checkmanufacturestatusoffinishedjewelry?orderNo=${orderNo}&jobNo=${jobNo}`,
      { responseType: 'text' } // 👈 Ensure response is treated as text
    );
  }


getStautusOfVarient(orderNo: any, jobNo: any): Observable<string>{
  return this.http.get(
    `${environment.receivablemanagementapi}salesorder/checkmanufacturestatusofvariant?orderNo=${orderNo}&jobNo=${jobNo}`,
    { responseType: 'text' }
  );
}

  getSalesOrderItem(salesordePayLoad):Observable<any> {

    return this.http.post<any>(environment.inventorymanagementapi + 'inventorylibrary/search', salesordePayLoad);
  }
  getData(id:String):Observable<any> {
    return this.http.get<any>(environment.manufacturemanagementapi + `custommanufacture/finddetailsofavariant?itemCode=${id}`);
  }
  saveSalesOrderJob(salesordeJobPayLoad):Observable<any> {
  

    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/savefnishedjewelleryandstyle', salesordeJobPayLoad);
  }
  getSalesOrderMaster(orderNo:String): Observable<any> {
    
    return this.http.get<any>(environment.receivablemanagementapi + 'pointsaleorderdata/'+orderNo );
  }

  getSalesOrderAddress(orderNo:String): Observable<any> {
    
    return this.http.get<any>(environment.receivablemanagementapi + 'pointsaleorderaddress/'+orderNo );
  }
  saveSalesOrderAddress(salesAddressPayLoad):Observable<any>{
    return this.http.post<any>(environment.receivablemanagementapi + 'pointsaleorderaddress', salesAddressPayLoad);
  }
  updateSalesOrder(orderNo:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.receivablemanagementapi + 'pointsaleorderdata/'+orderNo ,payload);
  }

  updateSalesOrderAddress(orderNo:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.receivablemanagementapi + 'pointsaleorderaddress/'+orderNo ,payload);
  }

  adcavceSearch(payload: any,page: number, size: number): Observable<any> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/advanceSearch' + qryParam,  payload);
  }
  getMissingItemItemByOrder(orderno:any){
    let qryParam = "?ordNo=" + orderno;;
    return this.http.get<any>(environment.receivablemanagementapi + 'salesorder/missingStoneinAnorder' + qryParam);
  }


  getSalesOrderItemById(orderSlNo:any){
    return this.http.get<any>(environment.receivablemanagementapi + 'pointSaleOrderJob/'+orderSlNo);
  }

 
  updateSalesOrderItems(ordlNo:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.receivablemanagementapi + 'salesorder/updatefnishedjewelleryandstyle/'+ordlNo ,payload);
  }

  updateSalesOrderItemsForStyles(ordlNo:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.receivablemanagementapi + 'salesorder/updatevariantfromautoprice/'+ordlNo ,payload);
  }

 

  deleteLineItems(ordSlNo: any): Observable<any> {
    return this.http.delete<any>(environment.receivablemanagementapi + 'salesorder/deletefinishedjewelleryandstyle/' + ordSlNo);
  }

  saveSalesOrdervariant(salesordervariantpayLoad):Observable<any> {
    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/savevariantitem', salesordervariantpayLoad);
  }

  findSalesOrdervariant(salesordervariantpayLoad):Observable<any> {
    
    return this.http.post<any>(environment.receivablemanagementapi + 'orderstylelibrary/search', salesordervariantpayLoad);
  }
  findSalesOrderMaterial(payload):Observable<any>{
    return this.http.post<any>(environment.receivablemanagementapi + 'orderstylelibrarymaterials/search', payload);

  }
  confirmOrder(payLoad): Observable<any> {

    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/confirmsalesOrder',payLoad );
  }

  splitOrder(payLoad): Observable<any> {
    
    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/splitsalesOrder',payLoad);
  }

  deleteVariantLineItems(id: any): Observable<any> {
    return this.http.delete<any>(environment.receivablemanagementapi + 'salesorder/deletevariantitem/' + id);
  }


  updateVariantOrderItems(id:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.receivablemanagementapi + 'orderstylelibrary/'+id ,payload);
  }

  findVariantOrderItemsVyId(id:String): Observable<any> {
    
    return this.http.get<any>(environment.receivablemanagementapi + 'orderstylelibrary/'+id );
  }
  findsizeofavariant(id:String): Observable<any> {
    
    return this.http.get<any>(environment.inventorymanagementapi + `custominventory1/findsizeofavariant?itemCode=${id}` );
  }
  deleteOrder(orderNo: any): Observable<any> {
    return this.http.delete<any>(environment.receivablemanagementapi + 'salesorder/deletesalesorder/' + orderNo);
  }

 

  findSizeValueOfAVariant(id:any): Observable<any> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'custominventory1/findSiveValueOfAVariant?libId='+ id );
  }

  getSalesOrderMoreDetails(id:any): Observable<any> {
    
    return this.http.get<any>(environment.receivablemanagementapi + 'salesorder/finishedJewlryotherdetails?ordSlNo='+ id );
  }

  getSalesOrderVariantMoreDetails(id:any):Observable<any> {
    
    return this.http.get<any>(environment.receivablemanagementapi + 'salesorder/styleotherdetails?psojId='+ id );
  }


  acceptQuote(payLoad:any): Observable<any> {
    
    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/converttosalesorder',payLoad );
  }

  salesorderitemstatussearch(payLoad:any): Observable<any> {
    
    return this.http.post<any>(environment.receivablemanagementapi + 'salesorder/salesorderitemstatussearch',payLoad );
  }
}
