import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '@services/authentication.service';
import { tap } from 'rxjs/operators';
import { SpinnerService } from '@services/core/spinner.service';
import { JwtDecodeService } from '@services/core/jwt-decode.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private spinnerService: SpinnerService,
    private jwtDecodeService: JwtDecodeService) { }

  private isUpdating = false;

  data: any = {};
  validatelogin: any;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    this.spinnerService.spinnerStarted();
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.access_token && localStorage.getItem('token')) {
      //check whether token expired
      let exp = this.jwtDecodeService.isExpired(currentUser.access_token);
      if (exp) {//if token expired call the refreshtoken endpoint
        let refreshtoken = currentUser.refresh_token;
        localStorage.removeItem('token');
        return this.authenticationService.refreshToken(refreshtoken);
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`,
          RefreshToken: `${currentUser.refresh_token}`,
          'Content-Type': 'application/json'
        }
      });
    }
    return next.handle(request).pipe(tap((event => {
      if (event instanceof HttpResponse) {
        this.spinnerService.spinnerEnded();
        }
    })));
  }


}
