<mat-card class="popup-container" #printSection id="printSection">
    <!-- <mat-card-header class="popup-header">
      <mat-card-title class="card-title">Style Catalog</mat-card-title>
      <div class="common-button button-mrg-right payables-preview-button ">
        <a class="button-golden" (click)="downloadPDF()">Download pdf</a>
    </div>
      <span class="close-icon" (click)="closePopup()">X</span>
       
    </mat-card-header> -->
    <div class="popup-header">

    <h2 class="card-title">Style catalog</h2>

    <div class="common-button button-mrg-right payables-preview-button ">
      <a class="button-golden" (click)="downloadPDF()">Download pdf</a>
  </div>
    <span class="close-icon" (click)="closePopup()">X</span>
  </div>
    <mat-card-content>
      <cdk-virtual-scroll-viewport itemSize="250" class="scroll-viewport" *ngIf="fullDataArray?.data?.length">
        <!-- Dynamic layout: grid for multiple items, centered for a single item -->
        <div [ngClass]="{'grid-container': fullDataArray.data.length > 1, 'single-record': fullDataArray.data.length === 1}">
          <div class="item-box" *ngFor="let item of fullDataArray.data">
            <div class="image-container">
              <!-- If imageUrl is missing, use a default placeholder -->
              <img [src]="item.imageUrl || 'assets/images/no-image.png'" 
     alt="Item Image" class="item-image" />
            </div>
            <div class="details"> 
              <p><strong>Item Code:</strong> {{ item.itemCode }}</p>
              <p><strong>Stock:</strong> {{ item.stock }}</p>
              <p><strong>Price:</strong> ${{ item.salesPrice | number:'1.2-2' }}</p>
              <p><strong>Category:</strong> {{ item.category }}</p>
              <p><strong>Specification:</strong> {{ item.specification }}</p>
            </div>
          </div>
        </div> 
      </cdk-virtual-scroll-viewport>
    </mat-card-content>
  
    <mat-card-actions class="button-container">
      <button mat-raised-button class="black-button" (click)="closePopup()">Close</button>
    </mat-card-actions>
  </mat-card>
  