import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemPopupComponent } from '../components/receivables/inv/invoicedetails/components/invoicesave/line-items/item-popup/item-popup/item-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryService } from '@services/inventory/invcontrolService/inventory.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
// import html2pdf from 'html2pdf.js';
//    import jsPDF from 'jspdf';
//   // import domtoimage from 'dom-to-image';
//    import domtoimage from 'dom-to-image-more';


//  import html2canvas from 'html2canvas';

@Component({
  selector: 'app-viewcatalog',
  templateUrl: './viewcatalog.component.html',
  styleUrls: ['./viewcatalog.component.scss']
})
export class ViewcatalogComponent implements OnInit,AfterViewInit {

  displayedColumns: string[] = ['image', 'itemCode',  'stock', 'salesPrice', 'category'];
  dataSource = new MatTableDataSource<any>();
  //fullDataArray: any[] = [];
  fullDataArray = new MatTableDataSource<any>(); // ✅ Correct type
  batchSize = 25000;
  currentPage = 0;
  allDataLoaded = false;
  fieldArray: any[] = [];
  // fullDataArray = {
  //   data: [
  //     {
  //       imageUrl: 'assets/images/item1.png',
  //       itemCode: 'ITM001',
  //       stock: 25,
  //       salesPrice: 49.99,
  //       category: 'Electronics',
  //       specification: 'Wireless Bluetooth Speaker'
  //     },
  //     {
  //       imageUrl: 'assets/images/item2.png',
  //       itemCode: 'ITM002',
  //       stock: 10,
  //       salesPrice: 129.99,
  //       category: 'Home Appliances',
  //       specification: 'Automatic Coffee Maker'
  //     },
  //     {
  //       imageUrl: '', // No image, will use default placeholder
  //       itemCode: 'ITM003',
  //       stock: 0,
  //       salesPrice: 59.5,
  //       category: 'Accessories',
  //       specification: 'Leather Wallet'
  //     },
  //     {
  //       imageUrl: 'assets/images/item4.png',
  //       itemCode: 'ITM004',
  //       stock: 5,
  //       salesPrice: 299.99,
  //       category: 'Gaming',
  //       specification: 'Mechanical Gaming Keyboard'
  //     }
  //   ]
  // };
  //fullDataArray: any[] = []; // Store the full dataset // ✅ Correct way to use MatTableDataSource
 @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
 @ViewChild('pdfContent', { static: false }) pdfContent!: ElementRef;
 @ViewChild('printSection', { static: false }) printSection!: ElementRef;


  constructor(public dialogRef: MatDialogRef<ViewcatalogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private inventoryService: InventoryService,
    private cd: ChangeDetectorRef
) { }

  ngOnInit(): void {
    console.log('🔄 Popup component initialized!');
    this.fieldArray = this.data.fieldArray;
    console.log('✅ Received fieldArray:', this.fieldArray);
    this.loadNextBatch(); // Try fetching data
  // this.loadData(); // Call method to load data
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.printSection) {
        console.error('printSection is still undefined');
      }
    }, 100);
  }

  loadData() {
    // this.fullDataArray = {
    //   data: [
    //     {
    //       imageUrl: 'assets/images/1.jpg',
    //       itemCode: 'ITM001',
    //       stock: 25,
    //       salesPrice: 49.99,
    //       category: 'Electronics',
    //       specification: 'Wireless Bluetooth Speaker'
    //     },
    //     {
    //       imageUrl: 'assets/images/2.jpg',
    //       itemCode: 'ITM002',
    //       stock: 10,
    //       salesPrice: 129.99,
    //       category: 'Home Appliances',
    //       specification: 'Automatic Coffee Maker'
    //     },
    
    //     {
    //       imageUrl: 'assets/images/4.jpg',
    //       itemCode: 'ITM004',
    //       stock: 5,
    //       salesPrice: 299.99,
    //       category: 'Gaming',
    //       specification: 'Mechanical Gaming Keyboard'
    //     }
    //   ]
    // };
  }

  loadNextBatch() {
    console.log('📢 loadNextBatch() called'); // Check if this line logs
  
    if (this.allDataLoaded) {
      console.log('⚠️ All data is already loaded. Stopping fetch.');
      return;
    }
  
    this.inventoryService.getInventoryStyleCatalog(this.fieldArray, this.currentPage, this.batchSize).subscribe(
      data => {
        console.log('✅ API Response:', data);
  
        if (Array.isArray(data) && data.length > 0) {
          const existingData = this.fullDataArray.data; // Get existing records
          this.fullDataArray = new MatTableDataSource([...existingData, ...data]); // Update table data
          this.currentPage++;
  
          console.log('📌 Data loaded successfully. Total records:', this.fullDataArray.data.length);
        } else {
          this.allDataLoaded = true;
          console.log('⚠️ No more data available.');
        }
      },
      error => {
        console.error('❌ Error fetching full data:', error);
      }
    );
  }
 
  // downloadPDF() {
  //   console.log('📥 Download PDF button clicked');
  
  //   const printSection = document.getElementById('printSection');
  //   if (!printSection) {
  //     console.error('❌ Print section not found');
  //     return;
  //   }
  
  //   // Create a temporary container for the HTML snapshot
  //   const tempContainer = document.createElement('div');
  //   tempContainer.innerHTML = printSection.outerHTML;
  //   document.body.appendChild(tempContainer);
  
  //   domtoimage.toPng(tempContainer, {
  //     useCORS: true,
  //     imageTimeout: 5000,  // Wait for images/fonts to load
  //     quality: 1
  //   })
  //   .then((dataUrl) => {
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  
  //     const imgWidth = 210;  // A4 width in mm
  //     const imgHeight = 297; // A4 height in mm
  
  //     pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
  //     pdf.save('document.pdf');
  
  //     console.log('✅ PDF created and downloaded successfully!');
  //     document.body.removeChild(tempContainer); // Clean up temp container
  //   })
  //   .catch((error) => {
  //     console.error('❌ Error generating PDF:', error);
  //     document.body.removeChild(tempContainer); // Ensure cleanup even on failure
  //   });
  // }

  // downloadPDF() {
  //   const element = this.printSection.nativeElement;
    
  //   Temporarily hide elements you don't want in the PDF
  //   const buttons = element.querySelectorAll('button, .close-icon, .payables-preview-button');
  //   buttons.forEach((button: HTMLElement) => {
  //     button.style.display = 'none';
  //   });

  //   Use html2canvas to capture the content
  //   html2canvas(element, {
  //     scale: 2, // Increase for better quality
  //     logging: false,
  //     useCORS: true, // This is important for loading external images
  //     allowTaint: true
  //   }).then((canvas) => {
  //     Restore the hidden elements
  //     buttons.forEach((button: HTMLElement) => {
  //       button.style.display = '';
  //     });

  //     Convert canvas to image
  //     const imgData = canvas.toDataURL('image/png');
      
  //     Calculate PDF dimensions
  //     const imgWidth = 210; // A4 width in mm
  //     const pageHeight = 295; // A4 height in mm
  //     const imgHeight = canvas.height * imgWidth / canvas.width;
  //     let heightLeft = imgHeight;
      
  //     Initialize PDF
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     let position = 0;

  //     Add first page
  //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     Add additional pages if content is too long
  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     Save the PDF
  //     pdf.save('style-catalog.pdf');
  //   });
  // }



  // async downloadPDF() {
  //   try {
  //     // 1. Convert all images to Base64 first
  //     await this.preloadImages();
  
  //     // 2. Hide buttons before capture
  //     const element = this.printSection.nativeElement;
  //     const buttons = element.querySelectorAll('button, .close-icon, .payables-preview-button');
  //     buttons.forEach((button: HTMLElement) => button.style.display = 'none');
  
  //     // 3. Generate PDF
  //     const canvas = await html2canvas(element, {
  //       scale: 2,
  //       logging: true,
  //       useCORS: true,       // Still needed for external images
  //       allowTaint: false    // Safer than `true`
  //     });
  
  //     // 4. Restore buttons
  //     buttons.forEach((button: HTMLElement) => button.style.display = '');
  
  //     // 5. Create PDF
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 210;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
  //     pdf.save('style-catalog.pdf');
  //   } catch (error) {
  //     console.error('PDF generation failed:', error);
  //     alert('Failed to generate PDF. Check console for details.');
  //   }
  // }
  
  // async preloadImages() {
  //   const images = this.printSection.nativeElement.querySelectorAll('img[src]');
    
  //   for (const img of images) {
  //     if (!img.src.startsWith('data:')) { // Skip already converted images
  //       try {
  //         img.src = await this.getBase64Image(img.src);
  //       } catch (error) {
  //         console.warn(`Failed to load image: ${img.src}`, error);
  //         img.src = 'assets/images/no-image.png'; // Fallback
  //       }
  //     }
  //   }
  // }
  
  // async getBase64Image(url: string): Promise<string> {
  //   // Use a proxy if you have CORS issues (see next section)
  //   const response = await fetch(url, { mode: 'cors' });
  //   if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
  //   const blob = await response.blob();
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.readAsDataURL(blob);
  //   });
  // }

  /////--------------------------------------------  working pdf----------------------------------///

  downloadPDF(): void {
  //   if (!this.printSection) {
  //     console.error('printSection is undefined');
  //     return;
  //   }
  //   const element = this.printSection.nativeElement;

  //  const tempContainer = document.createElement('div');
  //   tempContainer.innerHTML = this.printSection.nativeElement.outerHTML;
  //   document.body.appendChild(tempContainer);
  
  //   domtoimage.toPng(tempContainer, {
  //     useCORS: true,
  //     imageTimeout: 5000,  // Wait for images/fonts to load
  //     quality: 1
  //   })
  //   .then((dataUrl) => {
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  
  //     const imgWidth = 210;  // A4 width in mm
  //     const imgHeight = 297; // A4 height in mm
  
  //     pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
  //     pdf.save('document.pdf');
  
  //     console.log('✅ PDF created and downloaded successfully!');
  //     document.body.removeChild(tempContainer); // Clean up temp container
  //   })
  //   .catch((error) => {
  //     console.error('❌ Error generating PDF:', error);
  //     document.body.removeChild(tempContainer); // Ensure cleanup even on failure
  //   });
  }

  //-------------------------- working pdf----------------------------------//



  //-------------------------- currently working --------------------------------//
  // async downloadPDF(): Promise<void> {
  //   if (!this.printSection) {
  //     console.error('printSection is undefined');
  //     return;
  //   }

  //   const element = this.printSection.nativeElement;

  //   // Clone the section so we don't modify the actual UI
  //   const tempContainer = document.createElement('div');
  //   tempContainer.innerHTML = element.outerHTML;
  //   document.body.appendChild(tempContainer);

  //   try {
  //     // Convert images to base64 for reliable rendering
  //     await this.convertImagesToBase64(tempContainer);

  //     // Convert to PNG using dom-to-image
  //     const dataUrl = await domtoimage.toPng(tempContainer, {
  //       useCORS: true,  
  //       quality: 1
  //     });

  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 210;  // A4 width in mm
  //     const imgHeight = 297; // A4 height in mm

  //     pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
  //     pdf.save('Style_Catalog.pdf');

  //     console.log('✅ PDF created and downloaded successfully!');
  //   } catch (error) {
  //     console.error('❌ Error generating PDF:', error);
  //   } finally {
  //     document.body.removeChild(tempContainer); // Cleanup
  //   }
  // }

  // // Convert all <img> elements to base64 for dom-to-image compatibility
  // private async convertImagesToBase64(container: HTMLElement): Promise<void> {
  //   const images = container.querySelectorAll('img');

  //   const promises = Array.from(images).map(async (img) => {
  //     if (!img.src || img.src.startsWith('data:')) return; // Skip if already base64

  //     try {
  //       const response = await fetch(img.src, { mode: 'cors' });
  //       const blob = await response.blob();
  //       const reader = new FileReader();
  //       const base64Promise = new Promise<void>((resolve) => {
  //         reader.onloadend = () => {
  //           img.src = reader.result as string;
  //           resolve();
  //         };
  //       });

  //       reader.readAsDataURL(blob);
  //       await base64Promise;
  //     } catch (error) {
  //       console.error(`⚠️ Failed to load image: ${img.src}`, error);
  //     }
  //   });

  //   await Promise.all(promises); // Ensure all images are processed before proceeding
  // }


  //-------------------------- currently working --------------------------------//

  // downloadPDF() {
  //   console.log('📥 Download PDF button clicked');
  
  //   const printSection = document.getElementById('printSection');
  //   if (!printSection) {
  //     console.error('❌ Print section not found');
  //     return;
  //   }
  
  //   html2canvas(printSection, {
  //     useCORS: true,
  //     scale: 2, // Increases resolution for better clarity
  //     allowTaint: true, // Allow cross-origin images if CORS is enabled
  //     scrollX: 0,
  //     scrollY: 0,
  //     windowWidth: document.documentElement.scrollWidth,
  //     windowHeight: document.documentElement.scrollHeight
  //   }).then(canvas => {
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgData = canvas.toDataURL('image/png');
    
  //     const imgWidth = 210;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
    
  //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  //     pdf.save('document.pdf');
    
  //     console.log('✅ PDF created and downloaded successfully!');
  //   }).catch(error => {
  //     console.error('❌ Error generating PDF:', error);
  //   });
  // }
  

  // downloadPDF() {
  //   console.log('📥 Download PDF button clicked');
  
  //   const printSection = document.getElementById('printSection');
  //   if (!printSection) {
  //     console.error('❌ Print section not found');
  //     return;
  //   }
  
  //   Extract all styles from <style> tags (including component styles)
  //   const styles = Array.from(document.querySelectorAll('style'))
  //     .map(style => style.outerHTML)
  //     .join('');
  
  //   Open a new blank window
  //   const printWindow = window.open('', '_blank');
  //   if (!printWindow) {
  //     console.error('❌ Failed to open new window');
  //     return;
  //   }
  
  //   Inject styles and content into the new window
  //   printWindow.document.write(`
  //     <html>
  //     <head>
  //       <title>Download PDF</title>
  //       ${styles} <!-- Inject extracted styles -->
  //       <style>
  //         body { font-family: Arial, sans-serif; padding: 20px; }
  //         table { width: 100%; border-collapse: collapse; margin-top: 10px; }
  //         th, td { border: 1px solid black; padding: 8px; text-align: left; }
  //         th { background-color: #f2f2f2; }
  //       </style>
  //     </head>
  //     <body>
  //       <div id="capture">${printSection.outerHTML}</div>
  //       <script>
  //         window.onload = function() { 
  //           window.print(); 
  //           setTimeout(() => { window.close(); }, 1000);
  //         };
  //       </script>
  //     </body>
  //     </html>
  //   `);
  
  //   printWindow.document.close();
  
  //   Convert to PDF after rendering the styles
  //   setTimeout(() => {
  //     html2canvas(printSection, { scale: 2 }).then(canvas => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       const imgWidth = 210; // A4 width in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
  
  //       pdf.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight);
        
  //       Directly download the PDF
  //       pdf.save('download.pdf');
  //     });
  //   }, 1000); // Delay to ensure styles are applied
  // }




//   async downloadPDF() {
//     console.log('📥 Download PDF button clicked');

//     const printSection = document.getElementById('printSection');
//     if (!printSection) {
//         console.error('❌ Print section not found');
//         return;
//     }

//     // Convert all external images to data URLs to avoid CORS issues
//     const images = Array.from(printSection.querySelectorAll('img')); // Convert NodeList to Array
//     for (let img of images) {
//         try {
//             const response = await fetch(img.src, { mode: 'cors' });
//             const blob = await response.blob();
//             const reader = new FileReader();
//             reader.onloadend = () => { img.src = reader.result as string; };
//             reader.readAsDataURL(blob);
//         } catch (error) {
//             console.warn(`⚠️ Failed to load image: ${img.src}`, error);
//         }
//     }

//     // Ensure images are loaded before converting to PDF
//     setTimeout(() => {
//         html2canvas(printSection, { scale: 2 }).then(canvas => {
//             const imgData = canvas.toDataURL('image/png');
//             const pdf = new jsPDF('p', 'mm', 'a4');
//             const imgWidth = 210;
//             const imgHeight = (canvas.height * imgWidth) / canvas.width;

//             pdf.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight);
//             pdf.save('download.pdf');
//         });
//     }, 2000); // Extra delay to ensure images load
// }



  // downloadPDF() {
  //   console.log('📥 Download PDF button clicked');

  //   const printSection = document.getElementById('printSection');
  //   if (!printSection) {
  //       console.error('❌ Print section not found');
  //       return;
  //   }

  //   Extract all styles from <style> tags (including component styles)
  //   const styles = Array.from(document.querySelectorAll('style'))
  //       .map(style => style.outerHTML)
  //       .join('');

  //   Convert to PDF after rendering the styles
  //   setTimeout(() => {
  //       html2canvas(printSection, { scale: 2 }).then(canvas => {
  //           const imgData = canvas.toDataURL('image/png');
  //           const pdf = new jsPDF('p', 'mm', 'a4');
  //           const imgWidth = 210; // A4 width in mm
  //           const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

  //           pdf.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight);

  //           Directly download the PDF
  //           pdf.save('download.pdf');
  //       });
  //   }, 1000); 
  // }



  // downloadPDF() {
  //   console.log('📥 Download PDF button clicked');
  
  //   const printSection = document.getElementById('printSection');
  //   if (!printSection) {
  //     console.error('❌ Print section not found');
  //     return;
  //   }
  
  //   // Extract all styles from <style> tags (including component styles)
  //   const styles = Array.from(document.querySelectorAll('style'))
  //     .map(style => style.outerHTML)
  //     .join('');
  
  //   // Convert to PDF after rendering the styles
  //   html2canvas(printSection, { scale: 2 }).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 210; // A4 width in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
  
  //     pdf.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight);
      
  //     // Open PDF in a new tab with a download option once fully rendered
  //     const blobUrl = URL.createObjectURL(pdf.output('blob'));
  //     setTimeout(() => {
  //       window.open(blobUrl, '_blank');
  //     }, 500); // Ensure delay for proper loading
  //   });
  // }

  

  
  onScroll() {
    if (this.viewport.getRenderedRange().end === this.viewport.getDataLength()) {
      this.loadNextBatch();
    }
  }
  


printPage() {
  window.print();
}

closePopup() {
  this.dialogRef.close();
}

}
