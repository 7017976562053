import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { concat, forkJoin, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryService  {

  constructor(private http: HttpClient) { }
  finishTnumber( payload:any): Observable<any> {
    return this.http.put<any>(environment.inventorymanagementapi + 'custominventory/finishtnumber' , payload);

  }
  private inventoryUpdate = new Subject<any>();
  inventoryUpdate$ = this.inventoryUpdate.asObservable();

  // Emit new data when an update happens// 
  updateInventoryData(data: any) {
    this.inventoryUpdate.next(data);
  }

  // finishTnumber(searchPayLoad: any) {
  //   return this.http.post<any>(environment.inventorymanagementapi + 'custominventory/finishtnumber', searchPayLoad);
  // }

  getStoneLocationById(id): Observable<any> {
    return this.http.get<any>(environment.inventorymanagementapi + 'inventorystonevariant/' + id);
  }
  getDuplicateItemNo(itemCode: any):Observable<string> {

    return this.http.get(environment.inventorymanagementapi + 'custominventory1/checkduplicateitemcode/' + itemCode, { responseType: 'text' });
  }
  search(searchPayLoad: any) {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventory1', searchPayLoad);
  }
  getinventory1(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'inventory1/search' + qryParam, payload);
  }
  getlabourcharge(payload: any): Observable<any[]> {
    //let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/findlabourchargeofastone', payload);
  }
  updateVarientPrice(payload: any) {
    
    //let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/findvariantprice?variantId='+ payload,'');
  }
  getinventory2(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventory2/search', payload);
  }
  getAllLocations(): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'codeslocation');
  }
  getAllPlateSize(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'stoneplatedetails');
  }
  getAllPlateSizeByPayload(payload): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'stoneplatedetails/search',payload);
  }
  getPlateSizeAndPointersByMM(payload): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'stoneplatedetails/search', payload);
  }
  findVendorItemByCriteria(payload): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'vendoritem/search', payload);
  }
  getAllBrands(): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'brands');
  }
  getAllCategory(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'invcategory');
  }
  getCategory(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'invcategory/search' + qryParam, payload);
  }
  getCategorybypieces(payload: any): Observable<any[]> {
    
    return this.http.post<any>(environment.inventorymanagementapi + 'invcategory/search', payload);
  }
  getSubCategory(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'typedata/search' + qryParam, payload);
  }
  getAllCollectionKeywrd(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'collectionkeyword');
  }
  getAllCollectionNames():Observable<any[]>{
    return this.http.get<any>(environment.inventorymanagementapi + 'collectionnames');
  }
  getSubCollectionKeywrd(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'collectionsubkeyword/search', payload);
  }
  getMMStone(payload: any): Observable<any[]> {

    return this.http.post<any>(environment.inventorymanagementapi + 'inventorystonevariant/search', payload);
  }
  getUnitOfMeasure(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'invunitcharge');
  }
  getMadeIn(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'countrysetup');
  }
  getStoneOrigin(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'stoneorigin');
  }
  getInvRank(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'invrank');
  }

  getSettingByserach(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'codessetting/search', payload);
  }
  getSetting(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'codessetting');
  }
  getSettingById(settingId): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'codessetting/'+ settingId);
  }
  getPacking(): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'codespacking');
  }
  getMarketCode(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'marketcode');
  }
  getInvSummary(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + '');
  }
  getInvSummaryById(itemCode: string): Observable<any> {
    return this.http.get<any>(environment.inventorymanagementapi + 'invsummary/' + encodeURIComponent(itemCode) );
  }
  getMaterials(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'materialcodes');
  } 
  getMaterialsbypieces(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'materialcodes/search',payload);
  } 
  getMaterialsbyId(materialId): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'materialcodes/'+materialId);
  } 
  getsubcategory(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'typedata/search',payload);
  } 
  getsubcategoryById(typecode: any): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'typedata/'+typecode);
  } 
  
  getKarat(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'conversiontable');
  }
  getKaratbysearch(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'conversiontable/search',payload);
  }
  getKaratbyId(karatValue): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'conversiontable/'+ karatValue);
  }
  getColor(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'colorcodes');
  }

  getColorByserach(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'colorcodes/search', payload);
  }

  getColorById(colorId): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'colorcodes/'+ colorId);
  }
  getShapeByserach(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'shapemaintainance/search', payload);
  }
  getShapeById(shapeId): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'shapemaintainance/'+shapeId);
  }

  getShape(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'shapemaintainance');
  }

  getClearityByserach(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'claritymaintainance/search', payload);
  }
  getClearityById(clearityId): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'claritymaintainance/'+clearityId);
  }
  getClearity(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'claritymaintainance');
  }
  getWgtAndPriceByKarat(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/getweightnpricebykarat', payload);
  }
  getpricebyweight(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/getpricebyweight', payload);
  }
  getinventoryCollectionkeywordByItemcode(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorykeyword/search', payload);
  }
  getinventorySubCollectionkeywordByItemcode(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'Inventorysubkeyword/search', payload);
  }
  getInvDetails(itemCode: string): Observable<any> {
    return this.http.get<any>(environment.inventorymanagementapi + 'inventory1/' + encodeURIComponent(itemCode) );
  }
  finditemdetailsbyitemcode(itemCode: string): Observable<any> {
    return this.http.get<any>(environment.inventorymanagementapi + 'custominventory1/finditemdetailsbyitemcode/' + encodeURIComponent(itemCode));
  }
  inventorysearch(payload: any): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorystonevariant/search', payload);
  }
  
  getCodesLocation(locationcode: string): Observable<any> {
    return this.http.get<any>(environment.usrmanagementapi + 'codeslocation/' + locationcode);
  }
  getInvDetails2(itemCode: string): Observable<any[]> {
   
    return this.http.get<any>(environment.inventorymanagementapi + 'inventory2/' + encodeURIComponent(itemCode)  );
  }
  getInvStoneDetails(itemCode: string): Observable<any> {
    return this.http.get<any>(environment.inventorymanagementapi + 'invstone/' + encodeURIComponent(itemCode));
  }
  getInvSearchReslt(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory/advanceSearch' + qryParam, payload);
  }

  getCampaignItems(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory/inventorysearchforcampaign', payload);
  }
  saveVendorData(payload: any): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'vendoritem', payload);
  }
  checkVendor(vendorNo: string): Observable<any> {
    return this.http.get<any>(environment.usrmanagementapi + 'vendorData/' + vendorNo);
  }
  updateInvMaterial(itemCode: string, payload: any): Observable<any[]> {
    
    return this.http.put<any>(environment.inventorymanagementapi + 'inventory1/' + encodeURIComponent(itemCode)  , payload );
  }
  saveInvMaterialUsed(payload: any): Observable<any> {
  
    return this.http.post<any>(environment.inventorymanagementapi + 'invmaterialused', payload);
  }
  changedUrCost(requestDto: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/getpricebyitemcost', requestDto);
  }
  changedSalesPrice(requestDto: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/getpricebysalesprice', requestDto);
  }
  updateInventory(requestDto: any, itemCode: string): Observable<any[]> {
    
    return this.http.put<any>(environment.inventorymanagementapi + 'inventory1/' + encodeURIComponent(itemCode), requestDto);
  }

  updateNewInventory(requestDto: any, itemCode: string):Observable<any[]>{
    return this.http.put<any>(environment.inventorymanagementapi + 'inventory1/new/' + encodeURIComponent(itemCode), requestDto);
  }
  saveInvProduct(payload: any): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventoryalternate', payload);
  }
  deleteInvProduct(id: any): Observable<any> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'inventoryalternate/' + id);
  }
  getRelatedprodById(userId: String): Observable<any> {
    return this.http.get<any>(environment.inventorymanagementapi + 'inventoryalternate/' + userId);
  }
  update(userId: String, payload): Observable<any> {
    return this.http.put<any>(environment.inventorymanagementapi + 'inventoryalternate/' + userId, payload);
  }
  getByCritiria(payload: any): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventoryalternate/search', payload);
  }

  getByCritiriaMergeMap(payload: any): Observable<any>{
    return this.http.post<any>(environment.inventorymanagementapi + 'inventoryalternate/search', payload).pipe(
       mergeMap((service: any[]) => {
         return concat(...service.map(s => {
           return this.getInvDetails(s.itemCodeAlt)
         }) );
       }) 
      );

 }

  

  save(payload: any): Observable<any[]> {

    return this.http.post<any>(environment.inventorymanagementapi + 'inventory1', payload);
  }
  updateVendorItem(id: number, payload: any): Observable<any> {
    return this.http.put<any>(environment.inventorymanagementapi + 'vendoritem/' + id, payload);
  }
  searchVendorItem(payload: any) {
    return this.http.post<any>(environment.inventorymanagementapi + 'vendoritem/search', payload);
  }
  findInventory1ById(id: string) {
    return this.http.get<any>(environment.inventorymanagementapi + 'inventory1/' + encodeURIComponent(id) );
  }

  findInventoryFindings(id: number) {
    return this.http.get<any>(environment.inventorymanagementapi + 'invfindings/' + id);
  }
  saveInventoryFinding(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'invfindings', payload);
  }
  saveToSummary(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'invsummary', payload);
  }
  saveToInventory2(payload: any): Observable<any[]> {
    
    return this.http.post<any>(environment.inventorymanagementapi + 'inventory2', payload);
  }
  getStoneOriginList(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'stoneorigin');
  }
  saveToInvMarket(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorymarket', payload);
  }
  getInventory2Details(invId: string): Observable<any[]> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'inventory2/' + encodeURIComponent(invId) );
  }
  updateinventory2(itemCode: string, payload): Observable<any> {
   
    return this.http.put<any>(environment.inventorymanagementapi + 'inventory2/' + encodeURIComponent(itemCode) , payload);
  }
  updateInvProduct(id: number, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'inventoryalternate/' + id, payload);
  }
  deleteInvALternate(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'inventoryalternate/' + id);
  }
  deleteInvMaterialUsed(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'invmaterialused/' + id);
  }


  saveToCollectionKeyword(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorykeyword', payload);
  }

  customSaveToCollectionKeyword(payload: any , itemCode:string): Observable<any[]> {
    let qryParam = "?itemCode=" + itemCode;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/saveInventoryKeywords'+qryParam, payload);
  }
  saveToSubCollectionKeyword(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'Inventorysubkeyword', payload);
  }
  customSaveToSubCollectionKeyword(payload: any , itemCode:string): Observable<any[]> {
    let qryParam = "?itemCode=" + itemCode;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/saveInventorySubKeywords'+qryParam, payload);
  }
  saveTostoneDetails(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'invstone', payload);
  }
  updateMMStone(id, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'inventorystonevariant/' + id, payload);
  }
  updateTostoneDetails(itemCode, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'invstone/' + encodeURIComponent(itemCode) , payload);
  }
  getCollectionKeyword(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorykeyword/search', payload);
  }
  getSubCollectionKeyword(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'Inventorysubkeyword/search', payload);
  }
  getMarketSaved(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorymarket/search', payload);
  }

  deleteMaterialUsedSaved(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'invmaterialused/' + id);
  }
  updateInvMaterialUsed(itemCode: string, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'invmaterialused/' + encodeURIComponent(itemCode) , payload);
  }
  updateVendorData(itemcode: string, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'vendoritem/' + encodeURIComponent(itemcode), payload);
  }
  deleteVendorData(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'vendoritem/' + id);
  }
  deleteFindings(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'invfindings/' + id);
  }
  searchInventoryFindings(requestDto: any) {
    return this.http.post<any>(environment.inventorymanagementapi + 'invfindings/search', requestDto);
  }
  updateInventoryFinding(id: number, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'invfindings/' + id, payload);
  }
  getAllStyleNames(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'stylename');
  }

  updatePrice(payload): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/updateinventoryprice', payload);
  }
  getAllSize(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'sizemaintainance');
  }
  getSizeBysearch(payload): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'sizemaintainance/search',payload);
  }
  searchInvProductKeyword(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'productkeyword/search', payload);
  }
  saveInvProductKeyword(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'productkeyword', payload);
  }
  updateInvProductKeyword(keyUid: number, payload: any): Observable<any[]> {
    return this.http.put<any>(environment.inventorymanagementapi + 'productkeyword/' + keyUid, payload);
  }
  updatePieces(payload): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/updateinventorystock', payload);
  }

  getitemdetailsofanitem(itemCode: String, unitCharge: String, page: number, size: number): Observable<any[]> {
    let qryParam = "?itemCode=" + itemCode + "&unitCharge=" + unitCharge + "&page=" + page + "&size=" + size;
    return this.http.post<any[]>(environment.inventorymanagementapi + 'custominventory1/getitemdetailsofanitem' + qryParam, "100");
  }

  getWgtAndPriceByChangingKarat(itemKaratForCMV: any, actualItemKarat: number, actualItemWt: any, itemCode: any): Observable<any> {
    let qryParam = "?itemKaratForCMV=" + itemKaratForCMV +
      "&actualItemKarat=" + actualItemKarat +
      "&actualItemWt=" + actualItemWt +
      "&itemCode=" + itemCode;
    return this.http.get<any>(environment.inventorymanagementapi + 'custominventory/findpriceonkaratchange' + qryParam);

  }
  getStoneShapeList(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'shapemaintainance');
  }
  getStoneClarityList(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'claritymaintainance');
  }
  getStoneColourList(payload): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'colorcodes/search', payload);
  }
  saveMMStone(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'inventorystonevariant', payload);
  }

  inventoryStockReport(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/invstockport', payload);
  }
  downloadInventoryStockReportExcel(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/invstockportExcel', payload);
  }

  reportDataforPrintApprsl(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/printappraisalrepocontent', payload);
  }

  getWgtByKarat(payload: any): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/findWeightOnKaratChange', payload);
  }

  getInventoryFinishedJewelryCatalog(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/inventoryFinishedJewelryCatalog' + qryParam, payload);
  }
  getInventoryFindingsCatalog(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/inventoryFindingsCatalog' + qryParam, payload);
  }

  getInventoryStyleCatalog(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/inventoryStyleCatalog' + qryParam, payload);
  }

  getInventoryTnumberCatalog(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/inventoryTnumberCatalog' + qryParam, payload);
  }
  gettnumberAdvSearchReslt(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.inventorymanagementapi + 'custominventory1/tnumberAdvSearch' + qryParam, payload);
  }
  deletetNumber(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.inventorymanagementapi + 'custominventory1/deleteTnumber?tNo=' + id);
  }
  
  getKaratId(karat:any):Observable<any>{
    
    return this.http.get<any>(environment.inventorymanagementapi + 'conversiontable/' + karat);
  }

  getKaratValue(payload: any,):Observable<any>{
    
    return this.http.post<any>(environment.inventorymanagementapi + 'conversiontable/search' ,payload);
  }
  getStylebrand(itemCode: any,):Observable<any>{
    return this.http.get<any>(environment.inventorymanagementapi+"inventory1/" + itemCode);
  }
}
